import React, {useEffect, useCallback} from 'react'
import {useRecoilValue} from 'recoil';

import {individualScoreState} from './atom';

const ShowWonScore = () => {
    const wonInfo = useRecoilValue(individualScoreState);

    const onChange = useCallback((event) => {
       console.log(event);
    });
    // style={`top: ${wonInfo.y}; left: ${wonInfo.x}`}
    return (
        <div className={`ind-score ${wonInfo.class}`}  style={{left: wonInfo.x + 'px'}}>
            <p><span className='won-info' onChange={onChange}>{`${wonInfo.message === undefined ? '' : wonInfo.message}`}</span></p>
        </div>
    );
};

export default React.memo(ShowWonScore);
