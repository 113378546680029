import { isMobile } from 'react-device-detect';


export const COLORS = ['#EA4335', '#34A853', '#4285F4', '#FBBC05', '#EA4335', '#34A853'];

export const SIZES_DESKTOP = [150, 150, 150, 150, 150, 150, 150, 150, 150];

export const SIZES_MOBILE = [75, 75, 75, 75, 75, 75, 75, 75, 75];

export const SCORES = [10, 20, 30, 1000, -10, -20, -30, -1000, 10];

export const SYMBOLS_RARE = [13, 17, 18];

export const RARE_SCORES = [1000, -1000, 10];

export const SYMBOLS_DESKTOP = [0, 1, 2, 4, 5, 6];

export const SYMBOLS_MOBILE = [10, 11, 12, 13, 14, 15, 16, 17, 18];

export const SPEED_STEP = 50;

export const MAX_POINTS = 50;

export const SPAWN_INTERVAL = 500;
