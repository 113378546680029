import { isMobile } from 'react-device-detect';

import { MAX_POINTS, COLORS, SIZES_DESKTOP, SIZES_MOBILE, SYMBOLS_DESKTOP, SYMBOLS_MOBILE, SCORES, SYMBOLS_RARE } from './constants';


export const createDot = (pickedSymbols, pickedIndex) => {
    // pick random color and size
    // const size = SIZES[Math.floor(Math.random() * SIZES.length)]
    const SIZES = isMobile ? SIZES_MOBILE : SIZES_MOBILE;
    const SYMBOLS = pickedSymbols !== undefined ? pickedSymbols : SYMBOLS_MOBILE;
    if (pickedIndex == undefined || pickedIndex > 9) {
        pickedIndex = 9;
    }
    const symbol = SYMBOLS[pickedIndex];
    console.log('symbol', symbol, pickedSymbols, pickedIndex);
    const index = SYMBOLS_MOBILE.indexOf(symbol);
    const size = SIZES[index];
    const color = COLORS[index];
    const score = SCORES[index];
    // console.log("pickedSymbols", pickedSymbols);

    let x = Math.floor(Math.random() * 100);

    return {
        color,
        size,
        symbol,
        score,
        x,
        y: -150,
      }
};

export const removeDot = (dots, index) => {
    const newDots = [...dots];
    newDots.splice(index, 1);
    return newDots;    
};

export const calculatePoints = (dot) => {
    return dot.score;
};
