import React,{ useState, useEffect } from 'react';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

import { RecoilRoot } from "recoil";
import { WelcomeDesk } from './components/WelcomeDesk';
import { IntroAnimation } from './components/IntroAnimationDesk/IntroAnimation';
import { IntroAnimationMobile } from './components/introMobile/IntroAnimationMobile';
import {EndScreen} from './components/EndScreen';
import {EndScreenMobile} from './components/EndScreenMobile';
import { Login } from './components/Login';
import getAxiosInstance from "./config/http";
import {SessionEnd} from './components/SessionEnd';
import {SessionEndMobile} from './components/SessionEnd_Mobile';
import {ContinueGame} from './components/ContinueGame';
import {ContinueGameMobile} from './components/ContinueGameMobile';
import { WelcomeMob } from './components/WelcomeMob';
// import { FullScreen, useFullScreenHandle } from "react-full-screen";

import Game from './game/Game';
import GameMobile from './game/GameMobile';

import './App.css';

function App() {
  const [totalPoints, setTotalPoints] = useState(0);
  const [currentLevel, setCurrentlevel] = useState(-2); //default -1 - Welcome Scr
  const [currentSession, setCurrentSession] = useState({});
  const [numChestOpened, setNumChestOpened] = useState(0);
  const [isSessionActive, setIsSessionActive] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  
  const orientation = isMobile ? "mobile" : "";
  // const handle = useFullScreenHandle();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const [levelPrizes, setLevelPrizes] = useState([]);

  // Adding login, register and createSession logic based on strapi request.
  const login = () => async (userData) => {
    const http = getAxiosInstance();
    // console.warn(http.request.data);
    try {
      const { data } = await http.post("/auth/local-simple", userData);
      http.defaults.headers.common["Authorization"] = `bearer ${data.jwt}`;
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const register = () => async (userData) => {
    const http = getAxiosInstance();
    try {
      const { data } = await http.post("/auth/local-simple/register", userData);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const createSession = () => async (requestData) => {
    const http = getAxiosInstance();
    try {
      const { data } = await http.post("/game-sessions", requestData);
      setCurrentSession(data);
      // console.log("currentDATA", data)
      if (!data.finished && !data.didWin && data.gameState !== null && data.gameState.opened.length > 0) {
        setTotalPoints(data.gameState.totalPoints);
        setCurrentlevel(-1);
      } else {
        setCurrentlevel(-1);
      }
      return data;
    } catch (e) {
      throw e;
    }
  };

  // End of adding login, register and createSession.

  // Adding open chest logic based on strapi request.
  const bonusVockeOpen = (params) => async (params) => {
    // console.log(params);
    setIsLoaded(false);
    const http = getAxiosInstance();
    let _levelPrizes = [];
    try {
      const { data } = await http.post(
        `/merkurbet/bonusVocke/open?session=${params.session}`,
        { caughtSymbols: params.caughtSymbols, dots: params.dots, releasedDots: params.releasedDots }
      );
      // updateTotalPoints(data.gameState.points, data.gameState.totalPoints);
      // for (let i=0; i < 3; i++) {
      //   _levelPrizes.push([data.gameState.points, data.gameState.points, data.gameState.points]);
      // }
      // setLevelPrizes(_levelPrizes);
      setIsLoaded(true);
      return data;
    } catch (e) {
      console.log(e);
      setIsLoaded(true);
      // setError(e);
      // throw e;
    }
  };
  // End of adding open chest logic.

    // Adding bonus transfer logic based on strapi request.
    const bonusVockeTransfer = () => async () => {
      const http = getAxiosInstance();
      try {
        const { data } = await http.post(
          "/merkurbet/bonusVocke/transfer",
          {},
          {
            params: {session: currentSession.id}
          }
        );
      } catch (e) {
        console.log(e);
        // setError(e);
        // throw e;
      }
    };
    // End of bonus transfer logic.

  React.useEffect(() => {
    if (currentSession.finished) {
      if (currentSession.didWin) {
        setTotalPoints(currentSession.gameState.totalPoints);
        setCurrentlevel(4);
      } else {
        setIsSessionActive(false);
      }
    } else {
      setIsSessionActive(true);
    }
  }, [currentSession, isSessionActive])

  //Preload animation images
  useEffect(() => {
    let  levelBgImgs;
    // console.log('inIframe',inIframe());
    if (isMobile) {
      levelBgImgs = [
        './images/mobile/level1/img_4.png',
        './images/mobile/level1/img_3.png',
        './images/mobile/level1/img_2.png',
        './images/mobile/level1/img_1.png',
        './images/mobile/level1/img_0.png',
        './images/flower1click/img_0.png',
        './images/flower1click/img_1.png',
        './images/flower1open/img_0.png',
        './images/flower1open/img_1.png'      ]

    } else {
      levelBgImgs = [
        './images/welcome_desk/img_4.png',
        './images/welcome_desk/img_3.png',
        './images/welcome_desk/img_2.png',
        './images/welcome_desk/img_1.png',
        './images/welcome_desk/img_0.png',
        './images/flower1click/img_0.png',
        './images/flower1click/img_1.png',
        './images/flower1open/img_0.png',
        './images/flower1open/img_1.png'
      ]

    }
    
    cacheImages(levelBgImgs);
    
    //setIsLoading(false);
    return () => {
      //second;
    };
  }, []);
  const cacheImages = async (levelBgImgs) => {
    const promises = await levelBgImgs.map((src) => {
        return new Promise(function (resolve, reject) {
          const img= new Image();
          img.src = src;
          img.onload = resolve();
          img.onerror = reject();
          // console.log("img src: ", src);
        })
    })
    await Promise.all(promises);
    //setIsLoading(false);
  }
  useEffect(() => {
    const timer = setTimeout(() => {
        //setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);

  }, []);
  

  const animatePoints = (_points) => {
    let _toPoints = totalPoints + _points;
  }
  // const updateTotalPoints = () => {
  //   let _points = Number(levelPrizes[currentLevel-1][numChestOpened]);
    
  //   setNumChestOpened(numChestOpened+1);
  //   //animatePoints(_points);
  //   setTotalPoints(Number(totalPoints + _points));
  //   console.log("updateTotalPoints:", _points, totalPoints + _points);
  // }

  const updateTotalPoints = (points, totalPoints) => {
    let _points = Number(points);
    
    setNumChestOpened(numChestOpened+1);
    //animatePoints(_points);
    setTotalPoints(Number(totalPoints));
  }
  const updateLevel = (level) => {
    setCurrentlevel(level);
  }
  const loadNextLevel = () => {
    setIsLoading(true);
    document.getElementById('loader-init').style.display = "block";
    console.log(currentLevel);
    (currentLevel > 0) ? setCurrentlevel(currentLevel+1) : setCurrentlevel(2);
    // console.log(currentLevel);
  }

  const loadEndScreen = (score) => {
    setIsLoading(true);
    document.getElementById('loader-init').style.display = "block";
    setCurrentlevel(2);
    setTotalPoints(Number(score));
  }

  const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return e;
    }
  }

    return (
        // <div>
        //   <button onClick={handle.enter}>
        //     Enter fullscreen
        //   </button>
        //   <FullScreen handle={handle}>
            <RecoilRoot key="recoil-root">
              <div className={`App ${orientation}`} key="main-app">
                <header className="App-header">
                </header>
                <main key="main-window">
                  {isLoading && currentLevel < 2 && <div className='loaderIcon'><img src='./images/loader.gif' /></div>}
                  <MobileView className='mobile-view' key="browser-view-mobile">
                    {!isSessionActive &&  <SessionEndMobile /> }
                    {currentLevel === -3 && <ContinueGameMobile className='restartScr' updateLevel={updateLevel}  sessionState={currentSession} isLoading={() => {setIsLoading()}} bonusVockeOpen={bonusVockeOpen()} bonusVockeTransfer={bonusVockeTransfer()} />}
                    {currentLevel === -2 && <Login updateLevel={updateLevel} login={login()} register={register()} createSession={createSession()} />}
                    {currentLevel === -1 && <WelcomeMob isLoading={() => { setIsLoading(); } } updateLevel={updateLevel} />}
                    {currentLevel === 0 && <IntroAnimationMobile isLoading={() => {setIsLoading()}} updateLevel={updateLevel} currentLevel={currentLevel} currentSession={currentSession} /> }
                    {currentLevel === 1 &&  <GameMobile key="game-mobile" isLoading={() => {setIsLoading()}} currentSession={currentSession} currentLevel={currentLevel} handleNextLevel={() => { loadNextLevel()}} endScreen={() => { loadEndScreen() }} bonusVockeOpen={bonusVockeOpen()} bonusVockeTransfer={bonusVockeTransfer()} />}
                    {currentLevel > 1 && <EndScreenMobile totalPoints={totalPoints} currentSession={currentSession} handleNextLevel={() => { loadNextLevel(); } } isLoading={() => {setIsLoading()}} bonusVockeOpen={bonusVockeOpen()} bonusVockeTransfer={bonusVockeTransfer()} />}
                  </MobileView>
                  <BrowserView className='desktop-view' key="browser-view-desktop">
                    {!isSessionActive &&  <SessionEnd /> }
                    {currentLevel === -3 && <ContinueGame className='restartScr' updateLevel={updateLevel}  sessionState={currentSession} isLoading={() => {setIsLoading()}} bonusVockeOpen={bonusVockeOpen()} bonusVockeTransfer={bonusVockeTransfer()} />}
                    {currentLevel === -2 && <Login updateLevel={updateLevel} login={login()} register={register()} createSession={createSession()} />}
                    {currentLevel === -1 && <WelcomeDesk isLoading={() => { setIsLoading(); } } updateLevel={updateLevel} />}
                    {currentLevel === 0 && <IntroAnimation isLoading={() => {setIsLoading()}} updateLevel={updateLevel} currentLevel={currentLevel} currentSession={currentSession} /> }
                    {currentLevel === 1 &&  <Game key="game-desktop" isLoading={() => {setIsLoading()}} currentSession={currentSession} currentLevel={currentLevel} handleNextLevel={() => { loadNextLevel()}} endScreen={() => { loadEndScreen() }} bonusVockeOpen={bonusVockeOpen()} bonusVockeTransfer={bonusVockeTransfer()} />}
                    {currentLevel > 1 && <EndScreen totalPoints={totalPoints} currentSession={currentSession} handleNextLevel={() => { loadNextLevel(); } } isLoading={() => {setIsLoading()}} bonusVockeOpen={bonusVockeOpen()} bonusVockeTransfer={bonusVockeTransfer()} />}
                  </BrowserView>
                </main>
              </div>
            </RecoilRoot>
        //   </FullScreen>
        // </div>
      );
}

export default App;
