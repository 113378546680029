import React from 'react';
import {useRecoilValue} from 'recoil';

import {timerState} from './atom';

const Timer = () => {
    const timer = useRecoilValue(timerState);

    return (
        <div className="timer">
            <p><span className='number'>{`${Math.abs(timer).toFixed(0)}`}</span> sec</p>

        </div>
    );
};

export default React.memo(Timer);
