import React from 'react';

const Dot = (props) => {
    const {color, x, y, size, index, score, symbol, onClick} = props;    
    const dotStyle = {
        // background: symbol,
        // backgroundColor: color,
        background: `url(/images/symbols/${symbol}.png)`,
        height: `${size}px`,
        width: `${size}px`,
        left: `${x}px`,
        top: `${y}px`,
    };

    return (
        <div 
            className="dot"
            style={dotStyle}
            score={score}
            // onClick={() => onClick(index)}
        />
    );
};

export default Dot;
